import {useTranslation} from "react-i18next";
import {getNonEmptyColumns} from "../utils/SearchFormUtil";
import {formatTimestamp} from "../utils/DateUtils";

export const ChainAssortmentsTable = (props: { assortments: any }) => {
    const { t } = useTranslation()
    const { assortments } = props
    if(!assortments) {
        return null
    } else if (assortments.length === 0) {
        return <p>{t("Assortments.noAssortments")}</p>
    }
    const nonEmptyCols = getNonEmptyColumns(assortments)
    return (
        <div className={"table-wrapper"}>
            <p>{t("Assortments.chainAssortmentsHelperText")}</p>
            <table>
                <thead>
                <tr>
                    <th>{t("Common.chain")}</th>
                    <th>{t("Assortments.unit")}</th>
                    <th>{t("Assortments.layoutModuleId")}</th>
                    <th>{t("Common.validFrom")}</th>
                    <th>{t("Common.validTo")}</th>
                    <th>{t("Assortments.versionNumber")}</th>
                    <th>{t("Common.modifiedAt")}</th>
                    {nonEmptyCols.has("lmDepth") && <th>{t("Assortments.lmDepth")}</th>}
                    <th>{t("Assortments.lmDescription")}</th>
                    <th>{t("Assortments.lmHierarchyNode")}</th>
                    {nonEmptyCols.has("lmRole") && <th>{t("Assortments.lmRole")}</th>}
                    <th>{t("Assortments.lmSeries")}</th>
                    {/* Some columns are often empty so hiding them will save space from the page if they are not needed. */}
                    {nonEmptyCols.has("lmPriority") &&
                        <th dangerouslySetInnerHTML={
                            {__html: t('Assortments.lmPriorityWithSoftHyphen', {interpolation: {escapeValue: false}})}
                        } />
                    }
                    {nonEmptyCols.has("lmSpecialLmType") && <th>{t("Assortments.lmSpecialLmType")}</th>}
                    {nonEmptyCols.has("versionComponent") && <th>{t("Assortments.versionComponent")}</th>}
                    {nonEmptyCols.has("versionDisplayProductSokId") && <th>{t("Assortments.versionDisplayProductSokId")}</th>}
                    {nonEmptyCols.has("versionArticleHierarchy") && <th>{t("Assortments.versionArticleHierarchy")}</th>}
                    {nonEmptyCols.has("versionArticleHierarchyNode") && <th>{t("Assortments.versionArticleHierarchyNode")}</th>}
                    {nonEmptyCols.has("versionInformation") && <th>{t("Assortments.versionInformation")}</th>}
                </tr>
                </thead>
                <tbody>
                {
                    assortments.map((p: any) => {
                        return <tr key={"chain-assortment-" + p.id} title={"ID: " + p.id}>
                            <td>{p.salesOrg}</td>
                            <td>{p.unit}</td>
                            <td>{p.layoutModuleId}</td>
                            <td>{p.validFrom}</td>
                            <td>{p.validTo}</td>
                            <td>{p.versionNumber}</td>
                            <td>{formatTimestamp(p.sourceModifiedAt)}</td>
                            {nonEmptyCols.has("lmDepth") && <td>{p.lmDepth}</td>}
                            <td>{p.lmDescription}</td>
                            <td>{p.lmHierarchyNode}</td>
                            {nonEmptyCols.has("lmRole") && <td>{p.lmRole}</td>}
                            <td>{p.lmSeries}</td>
                            {nonEmptyCols.has("lmPriority") && <td>{p.lmPriority}</td>}
                            {nonEmptyCols.has("lmSpecialLmType") && <td>{p.lmSpecialLmType}</td>}
                            {nonEmptyCols.has("versionComponent") && <td>{p.versionComponent ? "X" : ""}</td>}
                            {nonEmptyCols.has("versionDisplayProductSokId") && <td>{p.versionDisplayProductSokId}</td>}
                            {nonEmptyCols.has("versionArticleHierarchy") && <td>{p.versionArticleHierarchy}</td>}
                            {nonEmptyCols.has("versionArticleHierarchyNode") && <td>{p.versionArticleHierarchyNode}</td>}
                            {nonEmptyCols.has("versionInformation") && <td>{p.versionInformation}</td>}
                        </tr>
                    })
                }
                </tbody>
            </table>
        </div>
    )
}

