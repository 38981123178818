import { useTranslation } from "react-i18next";
import { Scenario1Table } from "./bonus-buys/Scenario1Table";
import { Scenario2Table } from "./bonus-buys/Scenario2Table";
import { Scenario3Table } from "./bonus-buys/Scenario3Table";
import { PercentageDiscountsTable } from "./bonus-buys/PercentageDiscountsTable";
import { Site, siteArrayToMap } from "../utils/SitesApi";

export enum BonusBuysType {
    BONUSBUY,
    PERCENTAGE,
}

interface BonusBuysTableProps {
    data: any;
    sites: Site[];
    type: BonusBuysType;
}

export const BonusBuysTable = (props: BonusBuysTableProps) => {
    const { t } = useTranslation();
    const sitesMap = siteArrayToMap(props.sites);
    const { data, type } = props;

    if (!data) {
        return null;
    }

    switch (type) {
        case BonusBuysType.BONUSBUY:
            if (data.length === 0) {
                return <p>{t("BonusBuys.noBonusBuys")}</p>;
            }

            const filterByScenarioType = (bbItem: any, bbScenarioType: number) => {
                return bbItem["scenarioType"] === bbScenarioType;
            };

            const bonusBuysScenario1 = data.filter((bbItem: any) => filterByScenarioType(bbItem, 1));
            const bonusBuysScenario2 = data.filter((bbItem: any) => filterByScenarioType(bbItem, 2));
            const bonusBuysScenario3 = data.filter((bbItem: any) => filterByScenarioType(bbItem, 3));

            return (
                <div className="table-wrapper">
                    <p>{t("Prices.percentageDiscountsHelperText")}</p>
                    <ul className={"bb-explanations"}>
                        <li>{t("Prices.bbScenario1")}</li>
                        <li>{t("Prices.bbScenario2")}</li>
                        <li>{t("Prices.bbScenario3")}</li>
                    </ul>
                    <Scenario1Table bonusBuys={bonusBuysScenario1} sitesMap={sitesMap} />
                    <Scenario2Table bonusBuys={bonusBuysScenario2} sitesMap={sitesMap} />
                    <Scenario3Table bonusBuys={bonusBuysScenario3} sitesMap={sitesMap} />
                </div>
            );

        case BonusBuysType.PERCENTAGE:
            if (data.length === 0) {
                return <p>{t("PercentageDiscounts.noPercentageDiscounts")}</p>;
            }

            return (
                <div className="table-wrapper">
                    <p>{t("Prices.percentageDiscountsHelperText")}</p>
                    <PercentageDiscountsTable data={data} sitesMap={sitesMap} />
                </div>
            );

        default:
            return null;
    }
};
